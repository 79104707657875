export function useComponentId() {
	let uuid = 1;
	const componentId = `vue${uuid}`;
	uuid += 1;

	function getUniqueId(suffix) {
		return `${componentId}-${suffix}`;
	}

	function getAutomationId(suffix) {
		return `automation${suffix ? `-${suffix}` : ''}`;
	}

	return {
		getUniqueId,
		getAutomationId
	};
}
