<template>
	<BDropdownItem
		:id="id"
		:data-test-automation-id="automationId"
		:link-class="['item-container p-2', variant]"
		:value="value"
		@click="emitClick({ label, value }, id)"
	>
		<div v-if="iconLeft" class="px-1">
			<FontAwesomeIcon
				class="icon-left"
				:icon="['fal', iconLeft]"
				:size="iconLeftSize"
				aria-hidden="true"
			/>
		</div>
		<div class="px-2">
			<div class="d-flex flex-column text-left">
				<div class="label" :class="{ bold: active }">{{ label }}</div>
				<div v-if="subLabel" class="sub-label">{{ subLabel }}</div>
			</div>
		</div>
		<div v-if="active && iconActive" class="flex-grow-1 text-right pr-2">
			<FontAwesomeIcon
				class="icon-active"
				:icon="['fal', iconActive]"
				:size="iconActiveSize"
				aria-hidden="true"
			/>
		</div>
	</BDropdownItem>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { BDropdownItem } from 'bootstrap-vue';

defineProps({
	id: {
		type: String,
		default: 'base-dropdown-item'
	},
	automationId: {
		type: String,
		default: 'base-dropdown-item'
	},
	label: {
		type: String,
		required: true
	},
	subLabel: {
		type: String,
		default: null
	},
	value: {
		type: String,
		default: null
	},
	iconLeft: {
		type: String,
		default: null
	},
	iconLeftSize: {
		type: String,
		default: '1x'
	},
	iconActive: {
		type: String,
		default: null
	},
	iconActiveSize: {
		type: String,
		default: '1x'
	},
	active: {
		type: Boolean,
		default: false
	},
	variant: {
		type: String,
		default: 'primary'
	}
});

const emit = defineEmits(['click']);

function emitClick(label, id) {
	emit('click', label, id);
}
</script>

<style lang="scss" scoped>
::v-deep(.item-container) {
	display: flex;
	align-items: center;
	font-size: 15px;
	&.primary,
	&.secondary {
		color: $black-text;
		background-color: $white;
		& .bold {
			font-weight: 500;
		}
		& .sub-label {
			font-size: 14px;
		}
		&:hover,
		&:focus-visible {
			outline: none;
			background-color: $blue;
			color: $white;
			& .label {
				font-weight: 500;
			}
		}
		& .icon-left > svg,
		& .icon-active > svg {
			&:hover,
			&:focus-visible {
				color: $white !important;
			}
		}
	}
}
</style>
